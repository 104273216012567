<template>
  <div class="container wrapper" ref="scrollbox">
    <div class="content">
      <BaseLoad v-if="loading" />
      <div class="con" v-else>
        <!-- leve详情 -->
        <div class="flex item">
          <div class="item-l">
            <img :src="preSrc + info.cover" alt="" />
          </div>
          <div class="item-r">
            <h3 class="ellipsis" :title="info.name">{{ info.name }}</h3>
            <p class="desc">{{ info.desc }}</p>
            <div class="item-r-b between">
              <div>
                <span class="price">￥{{ info.price }}</span>
                <del class="del-price">{{ info.original_price }}</del>
              </div>
              <div>
                <button class="btn" v-if="info.buy_status == 1">
                  <i class="iconfont icon-duihao"></i> 已购买
                </button>
                <button
                  class="btn"
                  v-else
                  @click.stop="toPurchase(info.course_id, 3)"
                >
                  立即购买
                </button>
              </div>
            </div>
            <div class="flex favor-work">
              <CourseCancelFavor
                v-if="info.collect_status == 1"
                :cId="info.course_id"
                :cStatus="2"
                :cType="2"
                :fontSize="20"
                @favorCancel="handleFavorCanel"
              />
              <CourseFavor
                v-else
                :cId="info.course_id"
                :cStatus="1"
                :cType="2"
                :fontSize="20"
                @favorStatus="handleFavor"
              />
              <span class="slash">/</span>
              <WorkbenchCancel
                v-if="info.issue_status == 1"
                :cType="2"
                :cId="info.course_id"
                :cTitle="info.name"
                :cStatus="2"
                :fontSize="20"
                @workbenchCancel="workbenchCancel"
              />
              <Workbench
                v-else
                :cType="2"
                :cId="info.course_id"
                :cTitle="info.name"
                :cStatus="1"
                :fontSize="20"
                @handleWorkbench="handleWorkbench"
              />
            </div>
          </div>
        </div>
        <!-- 课件目录 -->
        <div class="course-title" v-if="list.length > 0">
          <p class="line"></p>
          <div class="title">
            <img src="@/assets/img/course/level-title.png" alt="" />
            <span>课件目录</span>
          </div>
        </div>
        <ul class="list" v-if="list.length > 0" ref='list'>
          <li
            class="flex item list"
            v-for="(item, index) in list"
            :key="item.lesson_id"
            @click.prevent="toLessonInfo(item.is_free, item.lesson_id)"
          >
            <div class="list-l">
              <img :src="preSrc + item.cover" alt="" />
            </div>
            <div class="list-r">
              <h3 class="ellipsis" :title="item.name">{{ item.name }}</h3>
              <p class="desc ellipsis">{{ item.desc }}</p>
              <div class=" between">
                <div class="free" v-if="item.is_free == 1">免费</div>
                <div v-else class="free hide"></div>
                <div class="flex">
                  <div class="flex favor-work">
                    <p
                      class="preview flex"
                      @click.stop="toLessonInfo(item.is_free, item.lesson_id)"
                    >
                      <img src="@/assets/img/course/preview.png" alt="" />
                      <span>预览</span>
                    </p>
                    <span class="slash">/</span>
                    <CourseCancelFavor
                      v-if="item.collect_status == 1"
                      :cId="item.lesson_id"
                      :cStatus="2"
                      :cType="3"
                      :cIndex="index"
                      :cItem="item"
                      :fontSize='18'
                      @favorCancel="handleFavorCanel"
                    />
                    <CourseFavor
                      v-else
                      :cId="item.lesson_id"
                      :cStatus="1"
                      :cType="3"
                      :cIndex="index"
                      :cItem="item"
                      :fontSize='18'
                      @favorStatus="handleFavor"
                    />
                    <span class="slash">/</span>
                    <WorkbenchCancel
                      v-if="item.issue_status == 1"
                      :cType="3"
                      :cId="item.lesson_id"
                      :cStatus="2"
                      :cItem="item"
                      :cIndex="index"
                      :fontSize='18'
                      @workbenchCancel="workbenchCancel"
                    />
                    <Workbench
                      v-else
                      :cType="3"
                      :cId="item.lesson_id"
                      :cTitle="item.name"
                      :cStatus="1"
                      :cItem="item"
                      :cIndex="index"
                      :fontSize='18'
                      @handleWorkbench="handleWorkbench"
                    />
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <BaseCopyright />
  </div>
</template>
<script>
import BaseEmpty from "@/components/empty/BaseEmpty.vue";
import BaseLoad from "@/components/loading/BaseLoading.vue";
import ListLoad from "@/components/loading/ListLoading.vue";
import {handlePreview} from "@/tools/GlobalFunction.js";
export default {
  components: {
    BaseEmpty,
    BaseLoad,
    ListLoad,
  },
  data() {
    return {
      preSrc: config.src,
      levelId: "",
      info: {},
      list: [],
      loading: true,
    };
  },
  created() {
    let _self = this;
    _self.levelId = _self.$route.params.levelId;
    _self.initData(_self.levelId);
  },
  mounted() {
    this.listenerFunction();
  },
  beforeDestroy(){
    this.beforeDestroy()
  },
  methods: {
    listenerFunction(e){
      document.addEventListener("scroll", this.handleScroll, true);
    },
    beforeDestroy(){
      document.removeEventListener('scroll', this.listenerFunction);
    },
    handleScroll(e){
      // console.log(e.target.scrollTop);
      // console.log(this.$refs.scrollbox.scrollTop);
    },
    gotop(){
      this.$refs.scrollbox.scrollTop = 0;
    },
    // 初始化
    initData(id) {
      let _self = this;
      _self.axios
        .post(config.url.levelInfo, _self.$qs.stringify({ course_id: id }))
        .then((res) => {
          if (res.code == 200) {
            _self.info = res.data.info;
            _self.list = res.data.list;
            setTimeout(() => {
              _self.loading = false;
            }, 150);
          } else if (res.code != 200 && res.code != 401) {
            _self.showTip("error", res.msg);
          }
        });
    },
    // 课件预览
    handlePreviewLesson(id) {
      handlePreview(id);
    },
    // 取消收藏
    handleFavorCanel(status, index, item) {
      let _self = this;
      if (item) {
        item.collect_status = status;
        _self.$set(_self.list, index, item);
        return;
      }
      _self.info.collect_status = status;
    },
    // 收藏
    handleFavor(status, index, item) {
      let _self = this;
      if (item) {
        item.collect_status = status;
        _self.$set(_self.list, index, item);
        return;
      }
      _self.info.collect_status = status;
    },
    // 发布到工作台
    handleWorkbench(status, index, item) {
      let _self = this;
      if (item) {
        item.issue_status = status;
        _self.$set(_self.list, index, item);
        return;
      }
      _self.info.issue_status = status;
    },
    // 取消发布到工作台
    workbenchCancel(status, index, item) {
      let _self = this;
      if (item) {
        item.issue_status = status;
        _self.$set(_self.list, index, item);
        return;
      }
      _self.info.issue_status = status;
    },
    // 到lesson详情
    toLessonInfo(is_free, id) {
      let _self = this;
      // 如果免费直接到预览，不是免费到顶部提示用户购买
      // if(is_free !== 1){
      //   this.showTip('warning','请先购买');
      //   this.gotop(); 
      //   return;
      // }
      handlePreview(id, this.gotop);
    },
    toPurchase(id, type) {
      let _self = this;
      _self.$router.push({
        name: "purchase",
        params: {
          id: id,
          type: type,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.con {
  .item {
    background: #fff;
    box-sizing: border-box;
    margin: 60px auto;
    .item-l {
      width: 450px;
      height: 300px;
      flex-shrink: 0;
      flex-grow: 0;
      border-radius: $border-radius-main;
      overflow: hidden;
      img {
        width: 450px;
        height: 300px;
        object-fit: contain;
      }
    }
    .item-r {
      width: 750px;
      height: 300px;
      flex-shrink: 0;
      flex-grow: 0;
      padding: 10px 30px 30px;
      box-sizing: border-box;
      border-left: 0;
      border-radius: 0 10px 10px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h3 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .desc {
        font-size: 18px;
        color: $font-color-51;
        line-height: 30px;
        height: 60px;
        margin-bottom: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; //行数
        -webkit-box-orient: vertical;
      }
      .item-r-b {
        background: #f9f9f9;
        border-radius: 5px 20px 20px 5px;
      }
      .price {
        font-size: 30px;
        color: $color-main;
        font-weight: bold;
        margin: 0 20px 0 30px;
      }
      .del-price {
        font-size: 18px;
        color: $font-color-9f;
      }
      .itme-r-b {
        background: $font-color-9f;
      }

      .icon-duihao {
        margin-right: 5px;
      }
    }
  }
  .icon-shoucang,
  .icon-star-filled {
    color: #f9e457;
    margin-right: 7px;
  }
  .favor i,
  .btn:hover,
  .list:hover {
    cursor: pointer;
  }
  .favor-work {
    color: $font-color-9f;
    margin-top: 50px;
    justify-content: flex-start;
  }
  .preview {
  img {
    width: 18px;
    height: 14px;
    margin: 2px 7px 0 0;
  }
  span{
    font-size: 18px;
  }
  }
  .slash {
    margin: 0 15px;
  }
  .favor {
    margin-top: -3px;
  }
  .favor i {
    margin-right: 6px;
  }
  .list {
    .list-l {
      width: 190px;
      height: 130px;
      flex-shrink: 0;
      flex-grow: 0;
      border-radius: $border-radius-main;
      overflow: hidden;
      img {
        width: 190px;
        height: 130px;
        object-fit: contain;
      }
    }
    .list-r {
      width: 1010px;
      height: 130px;
      flex-shrink: 0;
      flex-grow: 0;
      padding: 10px 30px;
      box-sizing: border-box;
      border-left: 0;
      border-radius: 0 10px 10px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h3 {
        font-size: 20px;
        font-weight: bold;
      }
      h3:hover {
        cursor: pointer;
        color: $color-main;
      }
      .desc {
        margin: 15px 0;
        color: $font-color-51;
      }
      .free {
        width: 60px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        color: #03cca6;
        background: #e4fbf6;
        border: 1px solid #03cca6;
        border-radius: 5px;
      }
      .hide {
        opacity: 0;
      }
      .favor-work {
        margin-top: 0;
      }
    }
  }
  .course-title {
    position: relative;
    margin-bottom: 80px;
    .line {
      width: 100%;
      height: 10px;
      background: #f9f9f9;
      border-radius: 5px;
    }
    .title {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -8px;
      img {
        width: 310px;
        height: 60px;
      }
      span {
        position: absolute;
        left: 50%;
        top: 45%;
        transform: translate(-50%, -50%);
        font-size: 30px;
        color: #fff;
      }
    }
  }
}
</style>
